<template>
  <div class="list-width">
    <div class="breadcrumb">
      <span>首页</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span>全部游戏</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span>{{ gameName }}</span>
    </div>
    <!-- 商品筛选条件 -->
    <waresFilter
      :goodsId="goodsId"
      v-if="goodsId"
      :gameName="gameName"
      :type="type"
      :title="title"
    />
  </div>
</template>
<script setup>
import waresFilter from './components/wares-filter.vue'
import { ref, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import {} from '@/api/home'
const route = useRoute()
const gameName = ref('')
const goodsId = ref('')
const type = ref('')
const title = ref('')
onMounted(() => {
  gameName.value = route.query.gameName
  goodsId.value = route.query.id
  type.value = route.query.type
  title.value = route.query.title
})
</script>
<style scoped lang="less">
.list-width {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
  .breadcrumb {
    margin: 14px 0;
    span:nth-child(1) {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
    img {
      margin: 0 3px;
    }
    span:nth-child(3) {
      cursor: pointer;
    }
    span:nth-child(5) {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
  }
}
</style>
