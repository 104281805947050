<template>
  <div>
    <div class="goods-filter">
      <div class="choose-close" @click="empty">
        <el-icon><Delete /></el-icon>
        <span>清空筛选条件</span>
      </div>
      <div class="goods-filter-item" v-for="(item, index) in specificationsList" :key="index">
        <p class="goods-condition-game">{{ item.name }}:</p>
        <div
          class="goods-condition-right"
          :class="item.moreDevelop == false ? 'develop' : ''"
          :ref="goodsCondition.set"
        >
          <p
            v-for="(option, i) in item.param"
            :key="i"
            :class="{ 'select-active': item.index === i }"
            @click="selectClick(option, index, i)"
          >
            {{ option }}
          </p>
        </div>
        <div class="goods-filter-right-more" @click="moreClick(index)" v-if="item.moreBtn">
          <text>{{ item.moreText }}</text>
          <img :src="item.moreImg" alt="" />
        </div>
      </div>
    </div>
    <div class="goods-filter" style="margin-top: 20px">
      <div class="goods-filter-item">
        <p class="goods-condition-game">价格区间:</p>
        <div class="goods-condition-right">
          <p :class="current === 0 ? 'select-active' : ''" @click="selectCurrent('1000', 0)">
            1000以下
          </p>
          <p :class="current === 1 ? 'select-active' : ''" @click="selectCurrent('1000-5000', 1)">
            1000-5000元
          </p>
          <p :class="current === 2 ? 'select-active' : ''" @click="selectCurrent('5000-10000', 2)">
            5000-10000元
          </p>
          <p :class="current === 3 ? 'select-active' : ''" @click="selectCurrent('10000', 3)">
            10000以上
          </p>
          <input type="text" placeholder="最低值" v-model="lowest" />
          <text>至</text>
          <input type="text" placeholder="最高值" v-model="highest" />
          <div class="btn" @click="getGoodsList">确定</div>
        </div>
      </div>
      <div class="goods-filter-item">
        <p class="goods-condition-game">商品标题:</p>
        <div class="goods-condition-right">
          <el-input
            type="text"
            placeholder="请输入商品标题"
            class="keywords-input"
            v-model="paramsObj.title"
            clearable
          />
          <div class="btn" @click="getGoodsList">确定</div>
        </div>
      </div>
      <div class="sequence-tabs">
        <div v-for="(item, index) in sequenceTabs" :key="index" @click="tabChange(item, index)">
          <text :class="currentTab == index ? 'tab-active' : ''">{{ item.label }}</text>
          <img :src="item.pic" alt="" />
        </div>
      </div>
    </div>
    <div class="list" v-if="goodsList.length > 0">
      <div class="item" v-for="item in goodsList" :key="item.id" @click="goodsDetails(item.id)">
        <div class="img">
          <img :src="item.image[0]" alt="" />
          <p class="official" v-if="item.admin_id > 0">官方发布</p>
          <p class="user" v-else>用户发布</p>
        </div>
        <div class="introduce">{{ item.title }}</div>
        <div class="price">
          <p>售价</p>
          <p>￥{{ item.price / 100 }}</p>
        </div>
        <div class="district">
          游戏区服：{{ item.params[0].name }}-{{ item.params[0].param_value }}
        </div>
      </div>
    </div>
    <div class="no-data" v-if="goodsList.length === 0">暂无数据</div>
    <div style="margin-top: 20px; text-align: center">
      <a-pagination
        v-model:current="page.pageSize"
        :total="total"
        @change="onChange"
        :defaultPageSize="8"
        :showSizeChanger="false"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick, reactive, inject } from 'vue'
import { ganeParams } from '@/api/goods'
import { getGoods } from '@/api/home'
import { useTemplateRefsList } from '@vueuse/core'
import { useRouter } from 'vue-router'
import { Delete } from '@element-plus/icons-vue'
const router = useRouter()
const moreImg = ref(require('@/assets/images/icon-down.png'))
const moreText = ref('展开')
// const moreDevelop = ref(true)
const goodsCondition = useTemplateRefsList()
const sequenceTabs = ref([
  { label: '综合排序' },
  { label: '发布时间', pic: require('@/assets/images/sequence.png') },
  { label: '商品价格', pic: require('@/assets/images/sequence.png') }
])
const currentTab = ref(0)
let props = defineProps({
  goodsId: String,
  gameName: String,
  type: String,
  title: String
})
const specificationsList = ref([])
const goodsList = ref([])
const page = reactive({
  pageNum: 8,
  pageSize: 1
})
const total = ref(null)
const paramsObj = reactive({
  game_id: props.goodsId,
  page: 1,
  page_size: 8,
  is_top: -1,
  title: ''
})
let arr = []
const lowest = ref('')
const highest = ref('')
const current = ref(null)
const reload = inject('reload')
//清空筛选
const empty = () => {
  reload()
}
function moreClick(index) {
  // moreDevelop.value = !moreDevelop.value
  specificationsList.value[index].moreDevelop = !specificationsList.value[index].moreDevelop
  if (specificationsList.value[index].moreDevelop === false) {
    specificationsList.value[index].moreImg = require('@/assets/images/icon-upout.png')
    specificationsList.value[index].moreText = '收起'
  } else {
    specificationsList.value[index].moreImg = require('@/assets/images/icon-down.png')
    specificationsList.value[index].moreText = '展开'
  }
}
function tabChange(item, index) {
  currentTab.value = index
}
const a = ref(1)
function selectClick(option, index, i) {
  specificationsList.value[index].index = i
  let param = {
    id: specificationsList.value[index].id,
    value: option
  }
  arr.push(param)
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (arr[i]['id'] == arr[j]['id']) {
        //判断是否存在重复的
        // 将最后点击的id的name 赋值给现有的id里面的name
        arr[i]['value'] = arr[j]['value']
        arr.splice(j, 1) // 移除数据里面重复的
      }
    }
  }
  paramsObj.params = arr
  getGoodsList()
}
const selectCurrent = (val, index) => {
  current.value = index
  if (val === '1000') {
    paramsObj.price_range = '0' + '-' + val
  } else {
    paramsObj.price_range = val
  }
  getGoodsList()
}
function getGaneParams() {
  specificationsList.value = []
  ganeParams(props.goodsId, 0).then((res) => {
    if (res.code == 200) {
      res.data.map((i, index) => {
        if (i.display != 3) {
          i.moreBtn = false
          i.moreDevelop = true
          i.moreImg = require('@/assets/images/icon-down.png')
          i.moreText = '展开'
          specificationsList.value.push(i)
          nextTick(() => {
            getHeight(i, index)
          })
        }
      })

      // nextTick(() => {
      // for (let i = 0; i < specificationsList.value.length; i++) {
      //   for (let k = 0; k < goodsCondition.value.length; k++) {
      //     console.log(goodsCondition.value[k])
      //     // console.log(goodsCondition.value[i].clientHeight)
      //     // console.log(goodsCondition.value[i].scrollHeight)
      //     if (goodsCondition.value[k].clientHeight < goodsCondition.value[k].scrollHeight) {
      //       console.log(111)
      //       specificationsList.value[i].moreBtn = true
      //     } else {
      //       console.log(222)
      //       // specificationsList.value[i].moreBtn = false
      //     }
      //   }
      // }

      // console.log(specificationsList.value)
      // })
    }
  })
}
const getHeight = (i, index) => {
  console.log(index)
  let clientHeight = goodsCondition.value[index].clientHeight
  let scrollHeight = goodsCondition.value[index].scrollHeight
  if (clientHeight < scrollHeight) {
    specificationsList.value[index].moreBtn = true
  } else {
    i.moreBtn = false
  }
}
function getGoodsList() {
  if (props.type === 'partBidding') {
    paramsObj.type = 2
  } else {
    paramsObj.type = 0
  }
  if (lowest.value || highest.value) {
    if (lowest.value && !highest.value) {
      highest.value = lowest.value
    }
    if (!lowest.value && highest.value) {
      lowest.value = 0
    }
    paramsObj.price_range = lowest.value + '-' + highest.value
  }
  getGoods(paramsObj).then((res) => {
    if (res.code == 200) {
      goodsList.value = res.data.list
      total.value = res.data.total
    }
  })
}
const onChange = (val) => {
  paramsObj.page = val
  getGoodsList()
}
function goodsDetails(id) {
  router.push({ path: '/goods', query: { id: id, type: props.type } })
}
onMounted(() => {
  getGaneParams()
  getGoodsList()
})
</script>
<style scoped lang="less">
.goods-filter {
  background: #ffffff;
  border-radius: 12px;
  padding: 30px;
  .choose-close {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: right;
  }
  .goods-filter-item {
    display: flex;
    //height: 30px;
    line-height: 30px;
    width: 100%;
    // justify-content: space-between;
    .goods-condition-game {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      white-space: nowrap;
      text-align: right;
      width: 12%;
      padding-right: 2%;
    }
    .goods-condition-right {
      width: 80%;
      // width: 1015px;
      // margin: 0 20px;
      height: 40px;
      overflow: hidden;
      p {
        padding: 0 10px;
        //background: linear-gradient(90deg, #eff8ff 0%, #eff8ff 100%);
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        display: inline-block;
        // margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .select-active {
        background: linear-gradient(90deg, #eff8ff 0%, #eff8ff 100%);
        color: #06a4f9;
      }
      input {
        width: 122px;
        height: 32px;
        background: #f4f4f4;
        border-radius: 4px;
        padding-left: 16px;
      }
      text {
        margin: 0 8px;
      }
      .keywords-input {
        width: 290px;
      }
      .btn {
        width: 72px;
        height: 32px;
        float: right;
        text-align: center;
        margin-left: 12px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        background: linear-gradient(270deg, #058aff 0%, #0dbaff 100%);
        border-radius: 5px;
        cursor: pointer;
      }
      .input-box {
        float: right;
      }
    }
    .develop {
      height: auto;
    }
    .goods-filter-right-more {
      display: flex;
      width: 6%;
      height: 28px;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      // padding: 0 10px;
      text {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
      img {
        width: 8px;
        height: 5px;
        margin-left: 6px;
      }
    }
  }
  .goods-filter-item:not(:first-child) {
    margin-top: 20px;
  }
  .sequence-tabs {
    display: flex;
    margin-top: 30px;
    text {
      cursor: pointer;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    img {
      margin-left: 2px;
      cursor: pointer;
    }
    div:nth-child(2) {
      margin: 0 32px;
    }
    .tab-active {
      color: #00b6fa;
    }
  }
}
.list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .item {
    width: 288px;
    height: 292px;
    background: #ffffff;
    border-radius: 12px;
    margin-top: 16px;
    margin-right: 15px;
    .img {
      position: relative;
      img {
        width: 288px;
        height: 140px;
        border-radius: 12px 12px 0 0;
      }
      .official {
        width: 59px;
        height: 19px;
        font-size: 12px;
        text-align: center;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
        position: absolute;
        background: url(../../../assets/images/authority-bg.png) no-repeat;
        top: 8px;
        left: 8px;
      }
      .user {
        width: 59px;
        height: 19px;
        font-size: 12px;
        text-align: center;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
        position: absolute;
        background: url(../../../assets/images/individual-bg.png) no-repeat;
        top: 8px;
        left: 8px;
      }
    }
    .introduce {
      width: 100%;
      padding: 6px 12px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
    .price {
      display: flex;
      justify-content: space-between;
      padding: 8px 12px;
      border-bottom: 1px solid #f2f2f2;
      P:nth-child(1) {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      P:nth-child(2) {
        font-size: 20px;
        font-family: DIN-Bold, DIN;
        font-weight: bold;
        color: #ff401b;
      }
    }
    .district {
      margin-top: 12px;
      margin-left: 12px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
  .item:nth-of-type(4n + 0) {
    margin-right: 0;
  }
}
.no-data {
  text-align: center;
  position: relative;
  background: url('~@/assets/images/no-data.png') no-repeat;
  width: 765px;
  height: 765px;
  margin: 0 auto;
  color: #6099f5;
  font-size: 24px;
  padding-top: 600px;
}
</style>
